import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { Button, Spinner } from 'reactstrap'

import PimsPatientSearchConfirm from './PimsPatientSearchConfirm'
import callFlask from '../../hasura/callFlask'
import { INTACT, NEUTERED } from '../request-consult/config'
import { Input } from '../common/Input'
import { User_users } from '../../hasura/graphQlQueries/types/User'
import { VetspirePatient } from '../../interfaces/VetspirePatient'
import { getVetspirePimsOrganization } from '../../lib/userHelpers'
import { speciesFor, trackHotjarEvent } from '../../lib/helpers'

const mapVetspirePatientValues: any = (patient: VetspirePatient) => {
  const weightUnit = patient.latestWeight.unit || patient.preferredWeightUnit || 'lb'
  return {
    id: patient.id,
    display_name: patient.name,
    birthdate: moment(patient.birthDate).format('MM/DD/YYYY'),
    breed: patient.breed,
    neutered: patient.neutered ? NEUTERED : INTACT,
    gender: patient.sex === 'UNKNOWN' ? undefined : patient.sex.toLowerCase(),
    species: speciesFor(patient.species),
    weight: `${patient.latestWeight.value}${weightUnit ? ` ${weightUnit}` : ''}`,
  }
}

// // 'Lemon' patient in Radimal Vetspire sandbox
// const TEST_VETSPIRE_PATIENT_ID = '39112'

interface Props {
  user: User_users
  update: (patient: VetspirePatient, notes?: string) => void
}

enum Status {
  Success = 'Success',
  Failure = 'Failure',
}

export default function PimsPatientSearch(props: Props) {
  const [isNetworking, setIsNetworking] = useState(false)
  const [result, setResult] = useState<{ patient: VetspirePatient; notes?: string } | undefined>()
  const [searchValue, setSearchValue] = React.useState()
  const [status, setStatus] = useState<Status | undefined>()

  const vetspire = getVetspirePimsOrganization(props.user)

  if (!vetspire) return null

  useEffect(() => {
    setStatus(undefined)
  }, [searchValue])

  const confirm = (confirmed: boolean) => {
    trackHotjarEvent('searched_pims_patient')
    if (confirmed && result) {
      props.update(mapVetspirePatientValues(result.patient), result.notes)
      setStatus(Status.Success)
    } else {
      setResult(undefined)
    }
  }

  const search = async () => {
    setIsNetworking(true)
    const result: { success: number; patient: VetspirePatient; notes?: string } = await callFlask(
      `/integrations/vetspire/patient?patient_id=${searchValue}&organization_id=${props.user.organization.id}`
    )
    setIsNetworking(false)
    if (result.success) {
      setResult(result)
    } else {
      setStatus(Status.Failure)
    }
  }

  const isSuccessful = status === Status.Success

  return (
    <div className="mb-2">
      {result && <PimsPatientSearchConfirm patient={result.patient} notes={result.notes || ''} confirm={confirm} />}

      <div className="d-flex align-items-end">
        <div>
          <p className="text-s m-0 bold">Import from Vetspire</p>
          <Input
            value={searchValue || ''}
            onChange={(e: any) => setSearchValue(e.target.value)}
            placeholder="Patient ID"
            type="text"
          />
        </div>

        <div className="d-flex align-items-end">
          <Button
            onClick={search}
            disabled={!searchValue || status !== undefined || isNetworking}
            className="ml-2 width-100px"
            color="success"
          >
            Import
          </Button>

          {isNetworking && <Spinner color="success" className="ml-2" size="sm" />}
        </div>
      </div>

      <p className={`m-0 text-xs bold ${status ? '' : 'hidden'} ${isSuccessful ? 'text--success' : 'text-danger'}`}>
        {isSuccessful ? 'Patient succesfully imported.' : 'Patient not found.'}
      </p>
    </div>
  )
}
